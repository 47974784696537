<template>
    <div id="app" :class="$route.meta.showTab ? 'app' : ''">
        <router-view />
        <tab-bar class="tab-bar" v-if="$route.meta.showTab" />
    </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";

export default {
    name: "App",
    components: { TabBar },
};
</script>

<style scoped>
.app {
    padding-bottom: 50px;
}
</style>
