import request from "@/utlis/request";

export function apiUserLogin(data) {
    return request({
        url: "/student/sign-in",
        method: "post",
        data,
    });
}

export function apiRegisterUsers(data) {
    return request({
        url: "/student/sign-up",
        method: "post",
        data,
    });
}

export function apiGetUserInfo() {
    return request({
        url: "/student/profile",
        method: "get",
    });
}

export function apiLogout(data) {
    return request({
        url: "/student/sign-out",
        method: "post",
        data,
    });
}

export function apiUpdateMessage(data) {
    return request({
        url: "/student/update",
        method: "post",
        data,
    });
}
