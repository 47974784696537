import {
    setToken,
    setUserInfo,
    getToken,
    getUserInfo,
    removeToken,
    removeUserInfo,
} from "@/utlis/cache";
import { apiUserLogin, apiGetUserInfo, apiLogout } from "@/api/user";

//持久化当前token 相关信息
export default {
    namespaced: true,
    state: {
        token: getToken() ? getToken() : undefined,
        userInfo: getUserInfo() ? JSON.parse(getUserInfo()) : undefined,
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            setToken(token);
        },
        SET_USERINFO(state, userInfo) {
            state.userInfo = userInfo;
            setUserInfo(JSON.stringify(userInfo));
        },
        REMOVE_USERINFO(state) {
            // 删除token，用户信息，登陆状态
            state.token = "";
            state.userInfo = {};
            state.code = "";
            removeToken();
            removeUserInfo();
        },
    },
    actions: {
        async userLogin({ commit, dispatch }, loginForm) {
            const { token } = await apiUserLogin(loginForm);
            commit("SET_TOKEN", token);
            await dispatch("setUserInfo");
        },
        async setUserInfo({ commit }) {
            const data = await apiGetUserInfo();
            commit("SET_USERINFO", data);
        },
        async userLogout({ commit }) {
            commit("REMOVE_USERINFO");
            await apiLogout();
        },
    },
};
