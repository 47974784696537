import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
    {
        //首页
        path: "/",
        name: "index",
        component: () => import("@/pages/IndexPage/IndexPage.vue"),
        meta: {
            showTab: true,
        },
    },
    {
        //考试列表
        path: "/my",
        name: "MyPage",
        component: () => import("@/pages/MyPage/MyPage.vue"),
        meta: {
            showTab: true,
        },
    },
    {
        //成绩列表
        path: "/grades",
        name: "MyGrades",
        component: () => import("@/pages/MyGrades/MyGrades.vue"),
        meta: {
            showTab: false,
        },
    },
    {
        // 登陆页面
        path: "/login",
        name: "LoginPage",
        component: () => import("@/pages/LoginPage/LoginPage.vue"),
        meta: {
            showTab: false,
        },
    },
    {
        // 注册页面
        path: "/register",
        name: "RegisterPage",
        component: () => import("@/pages/RegisterPage/RegisterPage.vue"),
        meta: {
            showTab: false,
        },
    },
    {
        // 注册页面
        path: "/exam",
        name: "ExamPage",
        component: () => import("@/pages/ExamPage/ExamPage.vue"),
        meta: {
            showTab: false,
        },
    },
    {
        // 用户资料
        path: "/user-message",
        name: "UserInfoPage",
        component: () => import("@/pages/UserInfoPage/UserInfoPage.vue"),
        meta: {
            showTab: false,
        },
    },
];
const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    mode: process.env.NODE_ENV === "production" ? "history" : "",
});

export default router;
