const token_name = "token";
const user_info_name = "user_info";

export function setToken(token) {
    return window.localStorage.setItem(token_name, token);
}
export function getToken() {
    return window.localStorage.getItem(token_name);
}
export function removeToken() {
    return window.localStorage.removeItem(token_name);
}

export function setUserInfo(user_info) {
    return window.localStorage.setItem(user_info_name, user_info);
}
export function getUserInfo() {
    return window.localStorage.getItem(user_info_name);
}
export function removeUserInfo() {
    return window.localStorage.removeItem(user_info_name);
}
