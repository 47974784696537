<template>
    <van-tabbar route v-model="active" safe-area-inset-bottom>
        <van-tabbar-item
            v-for="item in tabbarList"
            :key="item.title"
            :to="item.routerUrl"
            :icon="item.icon"
        >
            {{ item.title }}
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            tabbarList: [
                {
                    title: "考试",
                    routerUrl: "/",
                    icon: "home-o",
                },
                {
                    title: "我的",
                    routerUrl: "/my",
                    icon: "contact",
                },
            ],
        };
    },
};
</script>

<style>
</style>