import axios from "axios";
import store from "@/store";
import { Toast } from "vant";
import router from "@/router";
export default function request(config) {
    const instance = axios.create({
        baseURL:
            process.env.NODE_ENV === "production"
                ? "//test.abandon.cc/api/"
                : "/api/",
        timeOut: 5000,
    });
    instance.interceptors.request.use(
        (req) => {
            if (store.getters.token) {
                req.headers["Authorization"] = store.getters.token;
            }
            return req;
        },
        (err) => {
            return err;
        }
    );
    instance.interceptors.response.use(
        (res) => {
            const {
                status,
                data: { code, data, message },
            } = res;
            const httpSuccessCodes = [200];
            if (httpSuccessCodes.find((item) => item === status)) {
                const statusSuccessCodes = [0];
                if (
                    statusSuccessCodes.find((item) => item === code) ||
                    code === 0
                ) {
                    // 请求正常 返回正常值
                    return data;
                } else {
                    // 业务状态码异常抛出错误并阻塞请求
                    Toast.fail(message + ". code：" + code);
                    return Promise.reject(res);
                }
            } else {
                Toast.fail("系统异常，请联系管理员。");
                return Promise.reject(res);
            }
        },
        (err) => {
            const { status, data } = err.response;
            if (status === 401) {
                console.log(123);
                store.commit("user/REMOVE_USERINFO");
                router.push("/login");
                Toast.fail("身份认证失效，请重新登录");
                return Promise.reject(data);
            }
            Toast.fail("系统异常，请联系管理员。");
            return Promise.reject(data);
        }
    );
    return instance(config);
}
